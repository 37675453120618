<template>
	<navBar />
    <Loader v-if="loading"/>
	<div class="chat-container bg-white"> 
        <div class="col-12 ">
            <div class="d-flex">
                <div class="col-2 chat-threads">
                    <div class="p-2 box-shadow ">
                        <div>
                            <div class="justify-content-between" style="display:flex;align-items:center;">
                                <h3 class="mx-2 text-dark">Chats</h3>
                                <button class="btn btn-info btn-sm mb-2" @click="new_chat_modal = true">
                                    <i class="bx bx-plus"></i>
                                    New chat
                                </button>
                            </div>
                            <div class="d-flex">
                                <div class="search">
                                    <span class="position-absolute mt-2 mx-3 fs-4" style="z-index: 12;">
                                        <i class="bx bx-search"></i>
                                    </span>
                                    <input
                                        type="text"
                                        placeholder="Search messages"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="threads mt-2 ">
                            <div class="me-2">
                                <div @click="getThreadData(data)" class="d-flex p-1 chat-hover mb-2" v-for="data,i in data.list" :key="i">
                                    <div style="margin-right: 15px;">
                                        <!-- <img src="@/assets/images/profiles/1.jpg" height="50" width="50" style="border-radius: 100px;"> -->
                                        <img v-if="!data.recipient_dp" 
                                            height="50" width="50"
                                            class="raidus-100"
                                            src="@/assets/images/profiles/default.png" >
                                        <img
                                            v-else-if="data.recipient_dp"
                                            :src="`${image_api}/${data.recipient_dp}`"
                                            height="50" width="50"
                                            class="raidus-100"
                                        />
                                    </div>
                                    <div>
                                        <!-- <h6 class="fw-bold mb-0 mt-1" v-if="this.profile.username == data.owner">{{data.recipient}}</h6>
                                        <h6 class="fw-bold mb-0 mt-1" v-if="this.profile.username != data.owner">{{data.owner}}</h6> -->
                                        <h6 class="fw-bold mb-0 mt-1" >{{data.recipient}}</h6> 
                                        <span class="m-right text-theme text-10">Last message.</span>
                                        <span class="text-theme text-10">1hr</span>
                                    </div>
                                    <span class="chat-circle" v-if="data.status == 'unread'"></span>
                                </div>
                            </div>
                        </div>
                        <div class="threads-responsive mt-2 ">
                            <div class="me-2">
                                <div @click="viewChat(data)" class="d-flex p-2 chat-hover" v-for="data,i in data.list" :key="i">
                                    <div style="margin-right: 15px;">
                                        <!-- <img src="@/assets/images/profiles/1.jpg" height="50" width="50" style="border-radius: 100px;"> -->
                                        <img v-if="!data.recipient_dp" 
                                            height="50" width="50"
                                            class="raidus-100"
                                            src="@/assets/images/profiles/default.png" >
                                        <img
                                            v-else-if="data.recipient_dp"
                                            :src="`${image_api}/${data.recipient_dp}`"
                                            height="50" width="50"
                                            class="raidus-100"
                                        />
                                        <!-- <img 
                                            height="50" width="50"
                                            class="raidus-100"
                                            src="@/assets/images/profiles/default.png" > -->
                                    </div>
                                    <div>
                                        <!-- <h6 class="fw-bold mb-0 mt-1" v-if="this.profile.username == data.owner">{{data.recipient}}</h6>
                                        <h6 class="fw-bold mb-0 mt-1" v-if="this.profile.username != data.owner">{{data.owner}}</h6> -->
                                        <h6 class="fw-bold mb-0 mt-1" >{{data.recipient}}</h6> 
                                        <span class="m-right text-theme text-10">Last message.</span>
                                        <span class="text-theme text-10">1hr</span>
                                    </div>
                                    <span class="chat-circle" v-if="data.status == 'unread'"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7 chat-view" v-if="chat_toggle == true"  style="border-right: 1px solid #DCDEE2;">
                    <div class="chat-mate mb-1">
                        <div class="p-2 justify-content-between between" style="border-bottom: 1px solid #DCDEE2;">
                            <div class="d-flex cursor-pointer" @click="gotoProfile()">
                                <div>
                                    <!-- <img src="@/assets/images/profiles/1.jpg" height="42" width="42" style="border-radius: 100px;"> -->
                                    <img v-if="!get_data.recipient_dp" 
                                        height="50" width="50"
                                        class="raidus-100"
                                        src="@/assets/images/profiles/default.png" >
                                    <img v-if="get_data.recipient_dp" 
                                        height="50" width="50"
                                        class="raidus-100"
                                        :src="`${image_api}/${get_data.recipient_dp}`">
                                </div>
                                <div class="px-2">
                                    <!-- <h6 class="fw-bold mb-0 mt-1" v-if="chat_toggle == false && this.$route.query.name">{{this.$route.query.name}}</h6>
                                    <h6 class="fw-bold mb-0 mt-1" v-if="chat_toggle == true || this.$route.query.name">{{get_data.recipient}}</h6> -->
                                    <!-- <h6 class="fw-bold mb-0 mt-1" v-if="chat_toggle == true && (profile.username == get_data.owner)">{{get_data.recipient}}</h6>
                                    <h6 class="fw-bold mb-0 mt-1" v-if="chat_toggle == true && (profile.username != get_data.owner)">{{get_data.owner}}</h6>  -->
                                    <h6 class="fw-bold mb-0 mt-1" v-if="chat_toggle == true">{{get_data.recipient}}</h6> 
                                    <span class="m-right text-theme text-10">Active</span>
                                </div>
                            </div>
                            <div>
                                <i class="bx bxs-trash fs-3 text-danger" @click="delete_modal = true"></i>
                            </div>
                        </div>
                        <div class="messages pb-2 pe-2">
                            <div class="p-1 " v-for="data,i in this.get_data.messages" :key="i">
                                <span class="message-bubble" :class="data.from == this.profile.username ? 'chat-blue':'chat-grey'" v-if="data.text">{{data.text}}</span>
                                <div :class="data.from == this.profile.username ? 'chat-image-right':'chat-image-left'"  v-if="data.media">
                                    <div v-if="parsedMedia(data.media).length > 0" >
                                        <div v-for="(image, index) in parsedMedia(data.media)" :key="index" > 
                                            <img @click="postView(image)" v-if="isImageFile(image)" :src="getMediaUrl(image)" class="cursor-pointer" height="100" width="100" 
                                                style="border-radius:8px;"/>
                                            <video
                                                v-if="isVideoFile(image)"
                                                :src="getMediaUrl(image)"
                                                controls
                                                height="100"
                                                width="100"
                                                style="border-radius:8px;"
                                            ></video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="scroll-to"></div>
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="p-2">
                            <i @click="image_upload_modal = true" class="bx bx-image text-success" style="left: 18px !important; width:50px;"></i>
                            <input ref="commentInput" placeholder="Write something.."  maxlength="50" @keyup.enter="replyChat($event,false)"/>
                            <i class="bx bx-send send-icon" @click="replyChatButton()"></i>
                        </div>
                    </div>
                </div>
                <div class="col-3 chat-details" v-if="chat_toggle == true">
                    <div class="mt-3">
                        <div class="text-center">
                            <img src="@/assets/images/profiles/1.jpg" height="80" width="80" style="border-radius: 100px;">
                            <h5 class="mt-2">Name</h5>
                            <div>
                                <i class="bx bxs-user-circle fs-4 p-2 bg-light radius-100"></i><br>
                                <p class="mt-1 text-12">Profile</p>
                            </div>
                        </div>
                        <div>
                            <select class="media-files mx-2 px-2 pt-2 pb-2" v-model="media_files">
                                <option default hidden >Media/Files</option>
                                <option value="files">Files</option>
                                <option value="media">Media</option>
                            </select>
                        </div>
                        <div class="files" v-if="media_files == 'files'">
                        
                        </div>
                        <div class="media" v-if="media_files == 'media'">
                            <div class="p-2">
                                <img src="@/assets/images/profiles/1.jpg" style="height:150px;width: 150px;" v-for="i in 4" :key="i">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <b-modal 
            v-model="image_upload_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Upload Image" 
            centered
            >
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelect" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1" variant="info" @click="replyChatButton(true),image_upload_modal = false">Save</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="post_view_modal" 
            hide-footer
            size="md"
            title="View" 
            centered
            hide-header
            >
            <img class="post-view-image image-pointer" :src="`${image_api}/${this.post_view_media}`"/>
        </b-modal>
        <b-modal 
            v-model="new_chat_modal" 
            hide-footer
            size="md"
            title="Create New Chat" 
            centered
            >
            <div>
                <label>Username</label>
                <input class="form-control mb-3" placeholder="ex:(username01)" v-model="pl_new_chat.username"/>
            </div>
            <div class="text-end">
                <button class="btn btn-info btn-sm" @click="createNewChat()">Save</button>
            </div>
        </b-modal>
        <b-modal 
            v-model="delete_modal" 
            hide-footer
            size="md"
            title="Are you sure you want to delete this?" 
            centered
            >
            <div>
                <label>Enter your username</label>
                <input class="form-control mb-3" placeholder="ex:(username01)" v-model="pl_delete.username"/>
            </div>
            <div class="text-end">
                <button class="btn btn-danger btn-sm" @click="deleteConversation()">Delete</button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters} from "vuex";
import formatter from '@/mixins/formatter';
import Echo from "laravel-echo";
import Loader from '@/components/widgets/loader.vue';
import Swal from 'sweetalert2';
import $cookies from "vue-cookies";
export default {
    data() {
        return {
			data: {
				list: [],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
            get_data: [],
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
            media_files: "Media/Files",
            chat_toggle:false,
            newMessage: false,
            image_upload_modal: false,
            post_view_modal: false,
            loading:false,
            new_chat_modal:false,
            delete_modal:false,
            selectedMedia: [],
            post_view_media: [],
            pl_new_chat:{
                username: '',
            },
            pl_delete:{
                username: '',
            },
            message_count: 0,
        };
    },
    mixins:[formatter],
	components: {
		navBar,Loader
	},
	computed: {
        ...mapGetters('auth', ['profile']),
	},
    methods:{
		...mapActions("messages", {
			getThreads: "getList",
			storeThread: "newThread",
			checkerThread: "checkThread",
			messageUser: "message",
			showUserThread: "showThread",
			updateThreads: "updateThread",
			removeThread: "deleteThread",
		}),
        async initList(p) {
			var pl = {
				page: p,
			};
			const data = await this.getThreads(pl);
			this.data.list = data.data;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
            this.data.list.forEach(element => {
                if(element.status == 'unread'){
                    this.message_count ++;
                    $cookies.set("message_count", this.message_count);
                }
            });
            this.scrollToTop()
		},
        async updateMessage(data) {
			var pl = {
				status: data.status,
                id: data.id
			}
			await this.updateThreads(pl);
		},
        async deleteConversation() {
			var pl = {
                id: this.get_data._id
			}
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this conversation?',
                html: ``,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                if(this.profile.username.toLowerCase() == this.pl_delete.username.toLowerCase()){
                    const res = await this.removeThread(pl);
                    if (res.status == 200 || res.status == 'success') {
                        Swal.fire({
                            title: "Success",
                            html: `Conversation deleted.`,
                            type: "success",
                            icon: "success",
                            padding: "2em",
                        });
                        this.initList(1);
                        this.delete_modal = false;
                    }else{
                        Swal.fire({
                            html: res,
                            type: "error",
                            icon: "error",
                            padding: "2em",
                        });
                    }
                }else{
                    Swal.fire({
                        html: 'Make sure username text field is correct',
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
		},
        gotoProfile(){  
            if(this.profile.id == this.get_data._receiver_id){
                this.$router.push({path: "/profile"});
            }else if(this.profile.id != this.get_data._receiver_id){
                this.$router.push({
                    path:"/profile",
                    query: {
                        user_id: this.get_data._receiver_id,
                        name: this.get_data.owner,
                    }
                })
            }
        },
        scrollToElement(options) {
            console.log("scroll");
            const el = document.getElementById("scroll-to");
        if (el) {
            el.scrollIntoView(options);
        }
        },
        scrollToTop() {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo(0,0);
        },
        handleFileSelect(event) {
            this.selectedMedia = Array.from(event.target.files);
        },
        // PARSE
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        isImageFile(image) {
            return /\.(jpg|jpeg|png|gif)$/i.test(image);
        },
        isVideoFile(image) {
            return /\.(mp4|webm|ogg)$/i.test(image);
        },
        async getThreadData(i){
            this.chat_toggle = true;
            var pl ={
                id: i._id,
                sort: 'created_at',
                order: 'desc',
                limit: 50
            }
			const data = await this.showUserThread(pl);
            this.get_data = data;
            this.updateMessage({status: 'read',id: this.get_data._id})
            this.webSocket();
        },
        async viewChat(data){
            this.$router.push({
                path:"/view-chat",
                query: {
                    receiver_id: data._receiver_id,
                    thread_id: data._id,
                    name: data.recipient,
                    dp: data.recipient_dp ? data.recipient_dp : ''
                }
            })
            this.updateMessage({status: 'read',id: data._id})
        },
        async createNewChat(){
            var pl = {
                username : this.pl_new_chat.username
            }
            const res = await this.checkerThread(pl)
            if(res.status == 200 || res.status == 'success'){
                Swal.fire({
                    html: `You already have conversation with <strong>${this.pl_new_chat.username}</strong>.`,
                    type: "warning",
                    icon: "warning",
                    padding: "2em",
                });
                this.initList(1);
                this.pl_new_chat.username = '';
                this.new_chat_modal = false;
            }else{
                const fd = new FormData();
                fd.append('username', this.pl_new_chat.username);
                const success = await this.storeThread(fd);
                if(success.status == 200 || success.status == 'success'){
                    this.new_chat_modal = false;
                    this.pl_new_chat.username = '';
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: `<strong>${this.pl_new_chat.username}</strong> not found.`,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                    this.new_chat_modal = false;
                    this.pl_new_chat.username = '';
                }
            }
        },
        async replyChat(event,modal){
            const fd = new FormData();
            fd.append('thread_id', this.get_data._id);
            fd.append('receiver_id', this.get_data._receiver_id);
            if(modal == true){
                if(this.selectedMedia){
                    this.selectedMedia.forEach((file, index) => {
                        fd.append(`media[${index}]`, file);
                    });
                }
            }else{
                if(event.target.value){
                    fd.append('text', event.target.value);
                }
                if(this.selectedMedia){
                    this.selectedMedia.forEach((file, index) => {
                        fd.append(`media[${index}]`, file);
                    });
                }
            }
            this.loading = true;
            const res = await this.messageUser(fd);
            this.loading = false;
            if(res.status == 200 || res.status == 'success'){
                console.log(res)
                this.selectedMedia = []
                event.target.value = ''
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
        postView(data){
            this.post_view_modal = true;
            this.post_view_media = data;
        },
        replyChatButton(modal) {
            const commentInput = this.$refs.commentInput;
            const event = { target: commentInput };
            this.replyChat(event,modal);
        },
        webSocket() {
			window.Pusher = require("pusher-js");
			window.Echo = new Echo({
				broadcaster: "pusher",
				key: process.env.VUE_APP_PUSHER_APP_KEY,
				// wsHost: process.env.VUE_APP_PUSHER_HOST,
				wsPort: process.env.VUE_APP_PUSHER_PORT,
				disableStats: true,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				enabledTransports: ["ws", "wss"],
			});
			window.Echo.channel(`new-message.${this.get_data._id}`).listen(
				"NewMessageEvent",
				async (e) => {
					var pl = {
						text: e.text,
						media: e.media,
						from: e.from,
                        _thread_id: e.thread_id,
                        _receiver_id: e.receiver_id
					};
					await this.get_data.messages.push(pl);
                    this.updateMessage({status: 'unread',id: e.thread_id})
				}
			);
			// window.Echo.channel(`new-thread.${this.get_data._id}`).listen(
			// 	"NewThreadEvent",
			// 	async (e) => {
            //         this.updateMessage(e)
			// 	}
			// );
		},
    },
    mounted(){
        this.initList(1);
    }
};
</script>